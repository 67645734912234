<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <!-- <label>Entradas</label> -->
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            :to="{ name: 'banner-add'}"
          >
            Nuevo Contenido
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Buscar..."
            />

            <v-select
              v-model="categoriesFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="categories"
              class="invoice-filter-select mr-1"
              placeholder="Donde mostrar"
              multiple
              :close-on-select="false"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>

            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="invoice-filter-select"
              placeholder="Estado"
              label="text"
            >
              <template #selected-option="{ text }">
                <span class="text-truncate overflow-hidden">
                  {{ text }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refUBannerListTable"
      :items="fetchBanners"
      :fields="tableColumns"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      primary-key="id"
      empty-text="No se encontraron banners"
      class="position-relative"
      show-empty
      responsive
      striped
    >

      <template #head(invoiceStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: countries -->
      <template #cell(countries)="data">
        <div class="d-flex flex-column align-items-start">
          <div
            v-for="country in data.item.countries"
            :key="country"
            class="px-1"
            style="margin-bottom: 4px"
          >
            {{ country }}
          </div>
        </div>
      </template>

      <!-- Column: placeToShow -->
      <template #cell(placeToShow)="data">
        <div class="d-flex flex-column align-items-start">
          <div
            v-for="placeToShowItem in data.item.placeToShow"
            :key="placeToShowItem"
            class="px-1"
            style="margin-bottom: 4px"
          >
            {{ placeToShowItem }}
          </div>
        </div>
      </template>

      <!-- Column: classes -->
      <template #cell(classes)="data">
        <div class="d-flex flex-column align-items-start">
          <div
            v-for="classItem in data.item.classes"
            :key="classItem"
            class="px-1"
            style="margin-bottom: 4px"
          >
            {{ classItem }}
          </div>
        </div>
      </template>

      <!-- Column: languages -->
      <template #cell(languages)="data">
        <div class="d-flex flex-column align-items-start">
          <div
            v-for="lang in data.item.languages"
            :key="lang"
            class="px-1"
            style="margin-bottom: 4px"
          >
            {{ lang }}
          </div>
        </div>
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <span v-if="!data.item.status.variant">{{ data.item.status.text }}</span>
        <b-badge
          v-else
          :id="`banner-expired-${data.item.id}-expired-date`"
          :variant="data.item.status.variant"
          class="px-1"
          pill
        >
          {{ data.item.status.text }}
        </b-badge>

        <b-tooltip
          v-if="data.item.status.expired"
          :title="data.item.status.expired"
          :target="`banner-expired-${data.item.id}-expired-date`"
        />
      </template>

      <!-- Column: Position -->
      <template #cell(position)="data">
        <div class="cursor-pointer w-100 h-100">
          {{ data.item.position === null ? 'Sin posición' : data.item.position }}
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <div
            v-if="data.item.loading"
            class="text-center"
          >
            <b-spinner
              :id="`banner-row-${data.item.id}-loading-icon`"
              small
            />
          </div>

          <div v-else>
            <feather-icon
              :id="`banner-row-${data.item.id}-duplicate-icon`"
              icon="CopyIcon"
              size="16"
              class="mr-1 cursor-pointer"
              @click="onDuplicateBanner(data.item)"
            />
            <b-tooltip
              title="Duplicar Banner"
              :target="`banner-row-${data.item.id}-duplicate-icon`"
              placement="left"
            />

            <feather-icon
              :id="`banner-row-${data.item.id}-edit-icon`"
              icon="EditIcon"
              size="16"
              class="mr-1 cursor-pointer"
              @click="$router.push({ name: 'banner-edit', params: { id: data.item.id }})"
            />
            <b-tooltip
              title="Editar Banner"
              :target="`banner-row-${data.item.id}-edit-icon`"
              placement="left"
            />

            <feather-icon
              :id="`banner-row-${data.item.id}-delete-icon`"
              icon="TrashIcon"
              size="16"
              class="cursor-pointer"
              @click="onDelete(data.item)"
            />
            <b-tooltip
              title="Eliminar banner"
              :target="`banner-row-${data.item.id}-delete-icon`"
              placement="left"
            />
          </div>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalBanners"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  // BMedia,
  // BAvatar,
  // BLink,
  BBadge,
  // BDropdown,
  // BDropdownItem,
  BPagination,
  BTooltip,
  BSpinner,
} from 'bootstrap-vue'
import moment from 'moment'
// import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
// import useInvoicesList from './useBannerList'
import useCategories from '@/views/categories/useCategories'
import useBanners from '../useBanners'

import invoiceStoreModule from '../bannerStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    // BMedia,
    // BAvatar,
    // BLink,
    BBadge,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    BTooltip,
    BSpinner,

    vSelect,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    inDashboard: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    moment,
  },
  setup({ type, inDashboard }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { text: 'Publicado', value: 'PUBLISHED' },
      { text: 'No Publicado', value: 'NOT_PUBLISHED' },
    ]

    const {
      fetchBanners,
      bannerDelete,
      duplicateBanner,
      tableColumns,
      perPage,
      currentPage,
      totalBanners,
      dataMeta,
      perPageOptions,
      typeItem,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUBannerListTable,

      statusFilter,
      categoriesFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useBanners()

    const { categories } = useCategories()

    if (type) {
      typeItem.value = type
    }

    async function onDelete(banner) {
      try {
        const result = await this.$swal({
          title: '¿Estas seguro?',
          text: '¡No podrás revertir esta acción!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '¡Sí, bórralo!',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (result.value) {
          // eslint-disable-next-line no-param-reassign
          banner.loading = true
          await bannerDelete(banner.id)
          // eslint-disable-next-line no-param-reassign
          banner.loading = false
          refetchData()

          this.$swal({
            icon: 'success',
            title: '¡Borrado!',
            text: 'El banner ha sido borrado',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      } catch (error) {
        console.log(error)
      }
    }

    async function onDuplicateBanner(banner) {
      try {
        const result = await this.$swal({
          title: 'Duplicar',
          text: '¿Deseas duplicar el banner?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: '¡Sí, duplicalo!',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (result.value) {
          // eslint-disable-next-line no-param-reassign
          banner.loading = true

          await duplicateBanner(banner.id)

          // eslint-disable-next-line no-param-reassign
          banner.loading = false

          refetchData()

          this.$swal({
            icon: 'success',
            title: '¡Duplicado!',
            text: 'El banner ha sido duplicado',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      } catch (error) {
        console.log(error)
      }
    }

    function changePosition(item) {
      console.log('changePosition', item)
    }

    const keysNoDashboard = ['languages', 'type']

    return {
      fetchBanners,
      tableColumns: tableColumns.filter(column => (inDashboard ? !keysNoDashboard.includes(column.key) : column)),
      perPage,
      currentPage,
      totalBanners,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUBannerListTable,

      statusFilter,
      categoriesFilter,

      refetchData,
      onDelete,
      onDuplicateBanner,
      changePosition,

      statusOptions,
      categories,

      // avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  // ::v-deep .vs__selected-options {
  //   flex-wrap: nowrap;
  // }

  // ::v-deep .vs__selected {
  //   width: 100px;
  // }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
